<template>
    <b-modal
      v-model="data.showModal"
      button-size="sm"
      modal-class="filestore-modal"
      footer-class="w-100"
      no-fade
      @show="onShow"
      @hide="onClose"
      @close="onClose"
      size="xl"
      hide-header
      scrollable
    >
      <!-- Modal header -->
      <div slot="modal-title">
        <h1 class="flex gap-2 items-center text-2xl">
          <div class="font-bold">File</div>
          <div v-if="record" class="flex gap-2">
            <div>-</div>
            <div>{{ getFirstLine(record.custom_note) }}</div>
          </div>
        </h1>
      </div>

      <!-- Modal content -->
      <div v-if="record" class="flex flex-column gap-4">
        <div class="flex flex-column gap-2">
          <b-form-textarea
            v-model="record.custom_note"
            rows="4"
            max-rows="6"
            trim
            placeholder="Note"
          ></b-form-textarea>
        </div>

        <gallery
          :files="sortedFiles"
          :busy="uploadingCount > 0"
          @upload="uploadFile"
          @delete="deleteFile"
        />
      </div>
      <div v-else-if="isLoading">
        Loading..
      </div>

      <!-- Modal footer -->
      <div slot="modal-footer" class="w-100 flex justify-between gap-4 items-center">
        <div>
          <b-btn
            variant="white"
            size="sm"
            @click="onClose()"
            :disabled="isLoading"
          >
            Close
          </b-btn>
        </div>

        <div v-if="savingCount > 0 || uploadingCount > 0" class="flex gap-2 items-center">
          <b-spinner label="Spinning" small></b-spinner>
          <span>Saving..</span>
        </div>
        <div v-else>
          Changes are saved automatically.
        </div>

        <div class="flex gap-2">
          <b-btn
            variant="primary"
            size="sm"
            @click="downloadFiles()"
          >
            Download
          </b-btn>

          <b-btn
            variant="danger"
            size="sm"
            @click="deleteRecord()"
          >
            Delete
          </b-btn>
        </div>
      </div>
    </b-modal>
  </template>

<script>
import { downloadFile, getFirstLine } from '@/helpers';

const _ = require('lodash');

const Gallery = () => import('@/components/global/gallery/Gallery.vue');

export default {
  name: 'FileModal',
  components: {
    Gallery,
  },
  props: {
    data: Object,
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
    canSave() {
      if (!this.record) return false;
      if (this.isLoading) return false;
      return true;
    },
    sortedFiles() {
      return this.record.files.sort((b, a) => {
        if (a.mtime_ts !== b.mtime_ts) {
          return b.mtime_ts - a.mtime_ts;
        }
        return b.filename.localeCompare(a.filename);
      });
    },
  },
  data() {
    return {
      loadingCount: 0,
      savingCount: 0,
      uploadingCount: 0,
      record: null,
      file: null,
      ready: false,
      scheduleSaveRecord: _.debounce(() => {
        this.saveRecord();
      }, 250),
    };
  },
  methods: {
    onShow() {
      this.fetchRecord();
    },
    onClose() {
      this.saveRecord();
      this.data.showModal = false;
      this.$emit('close');
    },
    fetchRecord() {
      this.loadingCount++;
      this.$http
        .get(`/filestore/${this.data.record.id}`)
        .then((res) => {
          this.record = res.body.record;
          this.$nextTick().then(() => {
            this.ready = true;
          });
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch record: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    saveRecord() {
      this.loadingCount++;
      this.savingCount++;
      this.$http
        .put(`/filestore/${this.data.record.id}`)
        .send({ changes: this.record })
        .then(() => {
          this.$emit('change', this.data.record.id);
        })
        .catch((err) => {
          this.$toast.error(`Failed to update record: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
          this.savingCount--;
        });
    },
    deleteRecord() {
      if (!confirm(`Do you really wish to delete '${this.data.record.custom_note}'?`)) {
        return;
      }
      this.loadingCount++;
      this.$http
        .delete(`/filestore/${this.data.record.id}`)
        .then(() => {
          this.$emit('delete', this.data.record.id);
          this.onClose();
        })
        .catch((err) => {
          this.$toast.error(`Failed to delete record: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    uploadFile(file) {
      this.loadingCount++;
      this.uploadingCount++;
      const formData = new FormData();
      formData.append('file', file);
      formData.append('mtime', file.lastModified / 1000);
      this.$http
        .post(`/filestore/${this.data.record.id}/files`)
        .send(formData)
        .then((res) => {
          this.record.files.push(res.body.file);
          this.$emit('change', this.data.record.id);
        })
        .catch((err) => {
          this.$toast.error(`Failed to upload file: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
          this.uploadingCount--;
        });
    },
    deleteFile(file) {
      this.loadingCount++;
      this.$http
        .delete(`/filestore/${this.data.record.id}/files/${file.id}`)
        .then(() => {
          this.record.files = this.record.files.filter((f) => f.id !== file.id);
          this.$emit('change', this.data.record.id);
        })
        .catch((err) => {
          this.$toast.error(`Failed to delete file: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    downloadFiles() {
      downloadFile(`/api/filestore/${this.data.record.id}/download`);
    },
    getFirstLine(text) {
      return getFirstLine(text);
    },
  },
  watch: {
    record: {
      deep: true,
      handler() {
        if (!this.ready) {
          return;
        }
        this.scheduleSaveRecord();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
  .table-filestore {
    tr {
      border-top: 5px solid transparent;
    }

    tr > td:first-child {
      padding-right: 10px;
    }
  }
</style>
